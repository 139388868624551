/* General */

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,greek,greek-ext,latin-ext,vietnamese');

* {
  margin: 0;
}

body {
  font-family: 'Roboto', 'Roboto Condensed', sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

/* Fonts */

.hero h1 {
  font-size: 35px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  padding: 200px 0 0 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hero h2 {
  font-size: 16px;
  font-weight: 300;
  color: #e5e5e5;
  text-align: center;
  padding: 2px 0 0 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hero h2 a {
  color: #e5e5e5;
  text-decoration: none;
}

.hero h2 a:hover {
  color: #ffffff;
  text-decoration: none;
}

.wrapper h2 {
  font-size: 30px;
  color: #0d1041;
  padding: 0 0 30px 0;
  font-weight: 400;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

h3 {
  margin: 20px 0 0 0;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #0d1041;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.data-container h3 {
  text-align: center;
  padding: 0 0 10px 0;
}

h4 {
  font-size: 25px;
  text-align: center;
  margin: 0 0 55px 0;
  color: #0d1041;
  font-weight: 400;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.footer h5 {
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  padding: 25px 0 0 0;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.break {
	display: none;
}

button {
	border: none;
}

button img {
	cursor: pointer;
}

h5 a {
  color: #e5e5e5;
  text-decoration: none;
}

h5 a:hover {
  color: #fdfdfd;
}

p {
  font-size: 14px;
  line-height: 22px;
  color: #636060;
  padding: 20px 0 0 0;
  font-weight: 400;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p a {
  color: #0B71A1;
  text-decoration: none;
  font-weight: 400;
}

p a:hover {
  color: #129DDE;
}

.project-data p span {
  font-weight: 500;
  color: #1F1B63;
}

/* To all components */

.wrapper {
  margin: auto;
  max-width: 800px;
}

/* Hero */

.hero {
  height: 500px;
  background: #0d1041;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0d1041, #1F1B63,#0d1041);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0d1041, #1F1B63,#0d1041); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.animation-fade-in {
  position: relative;
  -webkit-animation: fade-in 5s; /* Safari 4.0 - 8.0 */
  animation: fade-in 5s;
}

/* About */

.about {
  padding: 80px 0;
}

.about img {
  border-radius: 50%;
  border: none;
  max-height: 170px;
  margin: 27px 20px 10px 0;
  float: left;
  pointer-events: none;
}

.emoji-face {
  font-size: 18px !important;
}

/* Projects */

.projects {
  padding: 80px 0;
}

.project-image {
  width: 100%;
  margin: auto;
  pointer-events: none;
}

.project-data {
  display: flex;
}

.introduction {
  padding: 50px 0 0 0;
}

.p-data-info {
  flex: 2;
}

.p-data-links {
  flex: 1;
  padding: 0 0 0 50px;
}

.proj img {
  text-align: center;
  margin: auto;
  padding: 0px;
}

/* Data */

.data {
  display: flex;
  margin: 0 0 50px 0;
}

.data-container {
  flex: 1;
}

.data-container button {
  border: none;
  background: none;
  opacity: 1;
}

.data-container button:hover {
  opacity: .8;
}

.data-container img {
  width: 100%;
}

/* Footer */

.footer {
  background: #0d1041;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #0d1041, #1F1B63,#0d1041);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #0d1041, #1F1B63,#0d1041); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
}

.footer-icon {
  height: 40px;
  padding: 25px 5px;
  opacity: .8;
}

.footer-icon:hover {
  opacity: 1;
}

@media only screen and (max-width: 900px) {
  .about {
    padding: 50px 0;
    margin: 50px;
  }

  .projects {
    padding: 0 0 50px 0;
    margin: 50px;
  }

  .introduction {
    padding: 20px 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .about {
    padding: 20px 0;
    margin: 50px 30px;
  }

  .projects {
    padding: 0 0 20px 0;
    margin: 50px 30px;
  }

  .about img {
    margin: auto;
    display: block;
    max-height: 175px;
    float: none;
  }

  .project-data {
    display: block;
  }

  .p-data-links {
    padding: 0;
  }

  h3 {
    margin: 20px 0 10px 0;
    font-size: 20px;
    line-height: 29px;
  }

  .data {
    display: block;
    margin: 0 0 50px 0;
  }

  .proj img {
    max-width: 100%;
  }

  .data-container button {
  	padding: 0;
  }

  .data-container button img {
    max-width: 100%;
  } 
	
  p {
    font-size: 16px;
    line-height: 24px;
  }

  .break {
    margin-top: 40px;
    display: block;
  }
}

@-webkit-keyframes fade-in {
  0%   {opacity: 0;}
  50%  {opacity: 1;}
}

@keyframes to-top {
  0%   {opacity: 0;}
  50%  {opacity: 1;}
}